define("discourse/plugins/chat/discourse/components/chat-message-separator-new", ["exports", "discourse-common/helpers/i18n", "truth-helpers/helpers/and", "truth-helpers/helpers/not", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _i18n, _and, _not, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatMessageSeparatorNew = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if (and @message.newest (not @message.formattedFirstMessageDate))}}
      <div class="chat-message-separator-new">
        <div class="chat-message-separator__text-container">
          <span class="chat-message-separator__text">
            {{i18n "chat.last_visit"}}
          </span>
        </div>
  
        <div class="chat-message-separator__line-container">
          <div class="chat-message-separator__line"></div>
        </div>
      </div>
    {{/if}}
  
  */
  {
    "id": "SyXMNMGy",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"newest\"]],[28,[32,1],[[30,1,[\"formattedFirstMessageDate\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"chat-message-separator-new\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-separator__text-container\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n          \"],[1,[28,[32,2],[\"chat.last_visit\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-separator-new.js",
    "scope": () => [_and.default, _not.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatMessageSeparatorNew;
});