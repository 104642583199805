define("discourse/plugins/chat/discourse/components/chat-user-info", ["exports", "@glimmer/component", "discourse/lib/url", "discourse/plugins/chat/discourse/components/chat-user-avatar", "discourse/plugins/chat/discourse/components/chat-user-display-name", "@ember/template-factory", "@ember/component"], function (_exports, _component, _url, _chatUserAvatar, _chatUserDisplayName, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatUserInfo extends _component.default {
    get avatarSize() {
      return this.args.avatarSize ?? "medium";
    }
    get userPath() {
      return (0, _url.userPath)(this.args.user.username);
    }
    get interactive() {
      return this.args.interactive ?? false;
    }
  }
  _exports.default = ChatUserInfo;
  _class = ChatUserInfo;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @user}}
        <ChatUserAvatar
          @user={{@user}}
          @avatarSize={{this.avatarSize}}
          @interactive={{this.interactive}}
        />
  
        {{#if this.interactive}}
          <a href={{this.userPath}} data-user-card={{@user.username}}>
            <ChatUserDisplayName @user={{@user}} />
          </a>
        {{else}}
          <ChatUserDisplayName @user={{@user}} />
        {{/if}}
      {{/if}}
    
  */
  {
    "id": "RL4I1D2x",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],null,[[\"@user\",\"@avatarSize\",\"@interactive\"],[[30,1],[30,0,[\"avatarSize\"]],[30,0,[\"interactive\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"interactive\"]],[[[1,\"        \"],[10,3],[15,6,[30,0,[\"userPath\"]]],[15,\"data-user-card\",[30,1,[\"username\"]]],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[\"@user\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-user-info.js",
    "scope": () => [_chatUserAvatar.default, _chatUserDisplayName.default],
    "isStrictMode": true
  }), _class);
});