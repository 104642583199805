define("discourse/plugins/chat/discourse/components/chat/routes/channel-info", ["exports", "@glimmer/component", "@ember/object", "@ember/routing", "@ember/service", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/modal/edit-channel-name", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat-channel-status", "@ember/template-factory", "@ember/component"], function (_exports, _component, _object, _routing, _service, _discourseI18n, _editChannelName, _navbar, _chatChannelStatus, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatRoutesChannelInfo = _exports.default = (_class = (_class2 = class ChatRoutesChannelInfo extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chatChannelInfoRouteOriginManager", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "modal", _descriptor3, this);
      _initializerDefineProperty(this, "chatGuardian", _descriptor4, this);
      _defineProperty(this, "membersLabel", _discourseI18n.default.t("chat.channel_info.tabs.members"));
      _defineProperty(this, "settingsLabel", _discourseI18n.default.t("chat.channel_info.tabs.settings"));
      _defineProperty(this, "backToChannelLabel", _discourseI18n.default.t("chat.channel_info.back_to_all_channels"));
      _defineProperty(this, "backToAllChannelsLabel", _discourseI18n.default.t("chat.channel_info.back_to_channel"));
    }
    get showTabs() {
      return this.site.desktopView && this.args.channel.isOpen;
    }
    get canEditChannel() {
      return this.chatGuardian.canEditChatChannel() && (this.args.channel.isCategoryChannel || this.args.channel.isDirectMessageChannel && this.args.channel.chatable.group);
    }
    editChannelTitle() {
      return this.modal.show(_editChannelName.default, {
        model: this.args.channel
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel-info">
        <Navbar as |navbar|>
          {{#if this.chatChannelInfoRouteOriginManager.isBrowse}}
            <navbar.BackButton
              @route="chat.browse"
              @title={{this.backToAllChannelsLabel}}
            />
          {{else}}
            <navbar.BackButton
              @route="chat.channel"
              @routeModels={{@channel.routeModels}}
              @title={{this.backToChannelLabel}}
            />
          {{/if}}
          <navbar.ChannelTitle @channel={{@channel}} />
        </Navbar>
  
        <ChatChannelStatus @channel={{@channel}} />
  
        <div class="c-channel-info">
          {{#if this.showTabs}}
            <nav class="c-channel-info__nav">
              <ul class="nav nav-pills">
                <li>
                  <LinkTo
                    @route="chat.channel.info.settings"
                    @model={{@channel}}
                    @replace={{true}}
                  >
                    {{this.settingsLabel}}
                  </LinkTo>
                </li>
                <li>
                  <LinkTo
                    @route="chat.channel.info.members"
                    @model={{@channel}}
                    @replace={{true}}
                  >
                    {{this.membersLabel}}
                  </LinkTo>
                </li>
              </ul>
            </nav>
          {{/if}}
  
          {{outlet}}
        </div>
      </div>
    
  */
  {
    "id": "h+GQ7+D2",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel-info\"],[12],[1,\"\\n      \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"chatChannelInfoRouteOriginManager\",\"isBrowse\"]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@title\"],[\"chat.browse\",[30,0,[\"backToAllChannelsLabel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[\"chat.channel\",[30,2,[\"routeModels\"]],[30,0,[\"backToChannelLabel\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[8,[30,1,[\"ChannelTitle\"]],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\\n      \"],[8,[32,1],null,[[\"@channel\"],[[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"c-channel-info\"],[12],[1,\"\\n\"],[41,[30,0,[\"showTabs\"]],[[[1,\"          \"],[10,\"nav\"],[14,0,\"c-channel-info__nav\"],[12],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,2],null,[[\"@route\",\"@model\",\"@replace\"],[\"chat.channel.info.settings\",[30,2],true]],[[\"default\"],[[[[1,\"\\n                  \"],[1,[30,0,[\"settingsLabel\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,2],null,[[\"@route\",\"@model\",\"@replace\"],[\"chat.channel.info.members\",[30,2],true]],[[\"default\"],[[[[1,\"\\n                  \"],[1,[30,0,[\"membersLabel\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[46,[28,[31,2],null,null],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"navbar\",\"@channel\"],false,[\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-info.js",
    "scope": () => [_navbar.default, _chatChannelStatus.default, _routing.LinkTo],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chatChannelInfoRouteOriginManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatGuardian", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editChannelTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editChannelTitle"), _class.prototype)), _class);
});