define("discourse/plugins/chat/discourse/components/chat/navbar/channel-title", ["exports", "@glimmer/component", "@ember/routing", "discourse/plugins/chat/discourse/components/channel-title", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _channelTitle, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatNavbarChannelTitle extends _component.default {}
  _exports.default = ChatNavbarChannelTitle;
  _class = ChatNavbarChannelTitle;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @channel}}
        <LinkTo
          @route="chat.channel.info.members"
          @models={{@channel.routeModels}}
          class="c-navbar__channel-title"
        >
          <ChannelTitle @channel={{@channel}} />
        </LinkTo>
      {{/if}}
    
  */
  {
    "id": "um69a3pp",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[32,0],[[24,0,\"c-navbar__channel-title\"]],[[\"@route\",\"@models\"],[\"chat.channel.info.members\",[30,1,[\"routeModels\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/channel-title.js",
    "scope": () => [_routing.LinkTo, _channelTitle.default],
    "isStrictMode": true
  }), _class);
});