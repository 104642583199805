define("discourse/plugins/chat/discourse/components/chat-channel-preview-card", ["exports", "@glimmer/component", "@ember/helper", "@ember/routing", "@ember/service", "@ember/utils", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/components/toggle-channel-membership-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _routing, _service, _utils, _concatClass, _i18n, _channelTitle, _toggleChannelMembershipButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatChannelPreviewCard = _exports.default = (_class = (_class2 = class ChatChannelPreviewCard extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
    }
    get showJoinButton() {
      return this.args.channel?.isOpen && this.args.channel?.canJoin;
    }
    get hasDescription() {
      return !(0, _utils.isEmpty)(this.args.channel?.description);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass
          "chat-channel-preview-card"
          (unless this.hasDescription "-no-description")
          (unless this.showJoinButton "-no-button")
        }}
      >
        <ChannelTitle @channel={{@channel}} />
        {{#if this.hasDescription}}
          <p class="chat-channel-preview-card__description">
            {{@channel.description}}
          </p>
        {{/if}}
        {{#if this.showJoinButton}}
          <ToggleChannelMembershipButton
            @channel={{@channel}}
            @options={{hash joinClass="btn-primary"}}
          />
        {{/if}}
        <LinkTo
          @route="chat.browse"
          class="chat-channel-preview-card__browse-all"
        >
          {{i18n "chat.browse_all_channels"}}
        </LinkTo>
      </div>
    
  */
  {
    "id": "11noQeda",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[\"chat-channel-preview-card\",[52,[51,[30,0,[\"hasDescription\"]]],\"-no-description\"],[52,[51,[30,0,[\"showJoinButton\"]]],\"-no-button\"]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n\"],[41,[30,0,[\"hasDescription\"]],[[[1,\"        \"],[10,2],[14,0,\"chat-channel-preview-card__description\"],[12],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showJoinButton\"]],[[[1,\"        \"],[8,[32,2],null,[[\"@channel\",\"@options\"],[[30,1],[28,[32,3],null,[[\"joinClass\"],[\"btn-primary\"]]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[32,4],[[24,0,\"chat-channel-preview-card__browse-all\"]],[[\"@route\"],[\"chat.browse\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,5],[\"chat.browse_all_channels\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\"],false,[\"unless\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-preview-card.js",
    "scope": () => [_concatClass.default, _channelTitle.default, _toggleChannelMembershipButton.default, _helper.hash, _routing.LinkTo, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});