define("discourse/plugins/chat/discourse/components/chat/routes/channel-threads", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/chat/thread-list/header", "discourse/plugins/chat/discourse/components/chat-thread-list", "@ember/template-factory", "@ember/component"], function (_exports, _component, _header, _chatThreadList, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatRoutesChannelThreads extends _component.default {}
  _exports.default = ChatRoutesChannelThreads;
  _class = ChatRoutesChannelThreads;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-routes-channel-threads">
        <ChatThreadListHeader @channel={{@channel}} />
        <ChatThreadList @channel={{@channel}} @includeHeader={{true}} />
      </div>
    
  */
  {
    "id": "TFqM66S4",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"c-routes-channel-threads\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@channel\",\"@includeHeader\"],[[30,1],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@channel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-threads.js",
    "scope": () => [_header.default, _chatThreadList.default],
    "isStrictMode": true
  }), _class);
});