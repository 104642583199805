define("discourse/plugins/chat/discourse/components/chat/list/item", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class Item extends _component.default {}
  _exports.default = Item;
  _class = Item;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{yield @item}}
    
  */
  {
    "id": "2gmfWwmu",
    "block": "[[[1,\"\\n    \"],[18,2,[[30,1]]],[1,\"\\n  \"]],[\"@item\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/item.js",
    "isStrictMode": true
  }), _class);
});