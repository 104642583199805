define("discourse/plugins/chat/discourse/modifiers/chat/scrollable-list", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/lib/later", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _later, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const UP = "up";
  const DOWN = "down";
  let ChatScrollableList = _exports.default = (_class = class ChatScrollableList extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [options] = _ref;
      this.element = element;
      this.options = options;
      this.lastScrollTop = this.computeInitialScrollTop();
      this.element.addEventListener("scroll", this.handleScroll, {
        passive: true
      });
      // listen for wheel events to detect scrolling even when at the top or bottom
      this.element.addEventListener("wheel", this.handleWheel, {
        passive: true
      });
    }
    handleScroll() {
      this.throttleComputeScroll();
    }
    handleWheel() {
      this.throttleComputeScroll();
    }
    computeScroll() {
      const scrollTop = this.element.scrollTop;
      this.options.onScroll?.(this.computeState());
      this.lastScrollTop = scrollTop;
    }
    throttleComputeScroll() {
      (0, _runloop.cancel)(this.scrollTimer);
      this.throttleTimer = (0, _runloop.throttle)(this, this.computeScroll, 50, true);
      this.scrollTimer = (0, _later.default)(() => {
        this.options.onScrollEnd?.(this.computeState());
      }, this.options.delay || 250);
    }
    cleanup() {
      (0, _runloop.cancel)(this.scrollTimer);
      (0, _runloop.cancel)(this.throttleTimer);
      this.element.removeEventListener("scroll", this.handleScroll);
      this.element.removeEventListener("wheel", this.handleWheel);
    }
    computeState() {
      const direction = this.computeScrollDirection();
      const distanceToBottom = this.computeDistanceToBottom();
      const distanceToTop = this.computeDistanceToTop();
      return {
        up: direction === UP,
        down: direction === DOWN,
        distanceToBottom,
        distanceToTop,
        atBottom: distanceToBottom.pixels <= 1,
        atTop: distanceToTop.pixels <= 1
      };
    }
    computeInitialScrollTop() {
      if (this.options.reverse) {
        return this.element.scrollHeight - this.element.clientHeight;
      } else {
        return this.element.scrollTop;
      }
    }
    computeScrollTop() {
      if (this.options.reverse) {
        return this.element.scrollHeight - this.element.clientHeight - this.element.scrollTop;
      } else {
        return this.element.scrollTop;
      }
    }
    computeDistanceToTop() {
      let pixels;
      const height = this.element.scrollHeight - this.element.clientHeight;
      if (this.options.reverse) {
        pixels = height - Math.abs(this.element.scrollTop);
      } else {
        pixels = Math.abs(this.element.scrollTop);
      }
      return {
        pixels,
        percentage: Math.round(pixels / height * 100)
      };
    }
    computeDistanceToBottom() {
      let pixels;
      const height = this.element.scrollHeight - this.element.clientHeight;
      if (this.options.reverse) {
        pixels = -this.element.scrollTop;
      } else {
        pixels = height - Math.abs(this.element.scrollTop);
      }
      return {
        pixels,
        percentage: Math.round(pixels / height * 100)
      };
    }
    computeScrollDirection() {
      if (this.element.scrollTop === this.lastScrollTop) {
        return null;
      }
      return this.element.scrollTop < this.lastScrollTop ? UP : DOWN;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleScroll", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleWheel", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleWheel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "computeScroll", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "computeScroll"), _class.prototype)), _class);
});