define("discourse/plugins/chat/discourse/components/chat/navbar/actions", ["exports", "@glimmer/component", "@ember/helper", "discourse/plugins/chat/discourse/components/chat/navbar/close-drawer-button", "discourse/plugins/chat/discourse/components/chat/navbar/close-thread-button", "discourse/plugins/chat/discourse/components/chat/navbar/close-threads-button", "discourse/plugins/chat/discourse/components/chat/navbar/full-page-button", "discourse/plugins/chat/discourse/components/chat/navbar/new-channel-button", "discourse/plugins/chat/discourse/components/chat/navbar/open-drawer-button", "discourse/plugins/chat/discourse/components/chat/navbar/thread-settings-button", "discourse/plugins/chat/discourse/components/chat/navbar/thread-tracking-dropdown", "discourse/plugins/chat/discourse/components/chat/navbar/threads-list-button", "discourse/plugins/chat/discourse/components/chat/navbar/toggle-drawer-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _closeDrawerButton, _closeThreadButton, _closeThreadsButton, _fullPageButton, _newChannelButton, _openDrawerButton, _threadSettingsButton, _threadTrackingDropdown, _threadsListButton, _toggleDrawerButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatNavbarActions extends _component.default {
    get openDrawerButtonComponent() {
      return _openDrawerButton.default;
    }
    get newChannelButtonComponent() {
      return _newChannelButton.default;
    }
    get threadTrackingDropdownComponent() {
      return _threadTrackingDropdown.default;
    }
    get closeThreadButtonComponent() {
      return _closeThreadButton.default;
    }
    get closeThreadsButtonComponent() {
      return _closeThreadsButton.default;
    }
    get threadSettingsButtonComponent() {
      return _threadSettingsButton.default;
    }
    get threadsListButtonComponent() {
      return _threadsListButton.default;
    }
    get closeDrawerButtonComponent() {
      return _closeDrawerButton.default;
    }
    get toggleDrawerButtonComponent() {
      return _toggleDrawerButton.default;
    }
    get chatNavbarFullPageButtonComponent() {
      return _fullPageButton.default;
    }
  }
  _exports.default = ChatNavbarActions;
  _class = ChatNavbarActions;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <nav class="c-navbar__actions">
        {{yield
          (hash
            OpenDrawerButton=this.openDrawerButtonComponent
            NewChannelButton=this.newChannelButtonComponent
            ThreadTrackingDropdown=this.threadTrackingDropdownComponent
            CloseThreadButton=this.closeThreadButtonComponent
            CloseThreadsButton=this.closeThreadsButtonComponent
            ThreadSettingsButton=this.threadSettingsButtonComponent
            ThreadsListButton=this.threadsListButtonComponent
            CloseDrawerButton=this.closeDrawerButtonComponent
            ToggleDrawerButton=this.toggleDrawerButtonComponent
            FullPageButton=this.chatNavbarFullPageButtonComponent
          )
        }}
      </nav>
    
  */
  {
    "id": "K1VpIDsV",
    "block": "[[[1,\"\\n    \"],[10,\"nav\"],[14,0,\"c-navbar__actions\"],[12],[1,\"\\n      \"],[18,1,[[28,[32,0],null,[[\"OpenDrawerButton\",\"NewChannelButton\",\"ThreadTrackingDropdown\",\"CloseThreadButton\",\"CloseThreadsButton\",\"ThreadSettingsButton\",\"ThreadsListButton\",\"CloseDrawerButton\",\"ToggleDrawerButton\",\"FullPageButton\"],[[30,0,[\"openDrawerButtonComponent\"]],[30,0,[\"newChannelButtonComponent\"]],[30,0,[\"threadTrackingDropdownComponent\"]],[30,0,[\"closeThreadButtonComponent\"]],[30,0,[\"closeThreadsButtonComponent\"]],[30,0,[\"threadSettingsButtonComponent\"]],[30,0,[\"threadsListButtonComponent\"]],[30,0,[\"closeDrawerButtonComponent\"]],[30,0,[\"toggleDrawerButtonComponent\"]],[30,0,[\"chatNavbarFullPageButtonComponent\"]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/navbar/actions.js",
    "scope": () => [_helper.hash],
    "isStrictMode": true
  }), _class);
});