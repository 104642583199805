define("discourse/plugins/chat/discourse/components/chat/message-creator/member", ["exports", "@ember/helper", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _helper, _dButton, _concatClass, _dIcon, _chatUserAvatar, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Member = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      class={{concatClass
        "chat-message-creator__member btn-default"
        (if @highlighted "-highlighted")
      }}
      @action={{fn @onSelect @member}}
    >
      <ChatUserAvatar
        @user={{@member.model}}
        @interactive={{false}}
        @showPresence={{false}}
      />
      <span class="chat-message-creator__member-username">
        {{@member.model.username}}
      </span>
      {{icon "times"}}
    </DButton>
  
  */
  {
    "id": "CDFi8KnG",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[16,0,[28,[32,1],[\"chat-message-creator__member btn-default\",[52,[30,1],\"-highlighted\"]],null]]],[[\"@action\"],[[28,[32,2],[[30,2],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[32,3],null,[[\"@user\",\"@interactive\",\"@showPresence\"],[[30,3,[\"model\"]],false,false]],null],[1,\"\\n    \"],[10,1],[14,0,\"chat-message-creator__member-username\"],[12],[1,\"\\n      \"],[1,[30,3,[\"model\",\"username\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[32,4],[\"times\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@highlighted\",\"@onSelect\",\"@member\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/member.js",
    "scope": () => [_dButton.default, _concatClass.default, _helper.fn, _chatUserAvatar.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = Member;
});