define("discourse/plugins/chat/discourse/components/chat-channel-title", ["exports", "@glimmer/component", "discourse/plugins/chat/discourse/components/channel-title", "@ember/template-factory", "@ember/component"], function (_exports, _component, _channelTitle, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class OldChatChannelTitle extends _component.default {}
  _exports.default = OldChatChannelTitle;
  _class = OldChatChannelTitle;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <ChannelTitle @channel={{@channel}} />
    
  */
  {
    "id": "fRcqXso4",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n  \"]],[\"@channel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-channel-title.js",
    "scope": () => [_channelTitle.default],
    "isStrictMode": true
  }), _class);
});