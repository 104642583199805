define("discourse/plugins/chat/discourse/components/chat/thread/header", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat/navbar", "discourse/plugins/chat/discourse/components/chat/thread/header-unread-indicator", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _replaceEmoji, _dIcon, _discourseI18n, _navbar, _headerUnreadIndicator, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatThreadHeader = _exports.default = (_class = (_class2 = class ChatThreadHeader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor2, this);
      _initializerDefineProperty(this, "site", _descriptor3, this);
    }
    get backLink() {
      const prevPage1 = this.chatHistory.previousRoute?.name;
      let route1, title1, models1;
      if (prevPage1 === "chat.channel.threads") {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else if (prevPage1 === "chat.channel.index" && !this.site.mobileView) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else if (!this.currentUser.isInDoNotDisturb() && this.unreadCount > 0) {
        route1 = "chat.channel.threads";
        title1 = _discourseI18n.default.t("chat.return_to_threads_list");
        models1 = this.channel?.routeModels;
      } else if (prevPage1 === "chat.threads") {
        route1 = "chat.threads";
        title1 = _discourseI18n.default.t("chat.my_threads.title");
        models1 = [];
      } else {
        route1 = "chat.channel.index";
        title1 = _discourseI18n.default.t("chat.return_to_channel");
        models1 = this.channel?.routeModels;
      }
      return {
        route: route1,
        models: models1,
        title: title1
      };
    }
    get channel() {
      return this.args.thread?.channel;
    }
    get headerTitle() {
      return this.args.thread?.title ?? _discourseI18n.default.t("chat.thread.label");
    }
    get unreadCount() {
      return this.channel?.threadsManager?.unreadThreadCount;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <Navbar as |navbar|>
        {{#if @thread}}
          <navbar.BackButton
            @route={{this.backLink.route}}
            @routeModels={{this.backLink.models}}
            @title={{this.backLink.title}}
          >
            <ChatThreadHeaderUnreadIndicator @channel={{this.channel}} />
            {{icon "chevron-left"}}
          </navbar.BackButton>
        {{/if}}
  
        <navbar.Title @title={{replaceEmoji this.headerTitle}} />
        <navbar.Actions as |action|>
          <action.ThreadTrackingDropdown @thread={{@thread}} />
          <action.ThreadSettingsButton @thread={{@thread}} />
          <action.CloseThreadButton @thread={{@thread}} />
        </navbar.Actions>
      </Navbar>
    
  */
  {
    "id": "MGCNeDn/",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[8,[30,1,[\"BackButton\"]],null,[[\"@route\",\"@routeModels\",\"@title\"],[[30,0,[\"backLink\",\"route\"]],[30,0,[\"backLink\",\"models\"]],[30,0,[\"backLink\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,1],null,[[\"@channel\"],[[30,0,[\"channel\"]]]],null],[1,\"\\n          \"],[1,[28,[32,2],[\"chevron-left\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[32,3],[[30,0,[\"headerTitle\"]]],null]]],null],[1,\"\\n      \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"ThreadTrackingDropdown\"]],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n        \"],[8,[30,3,[\"ThreadSettingsButton\"]],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n        \"],[8,[30,3,[\"CloseThreadButton\"]],null,[[\"@thread\"],[[30,2]]],null],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"]],[\"navbar\",\"@thread\",\"action\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/thread/header.js",
    "scope": () => [_navbar.default, _headerUnreadIndicator.default, _dIcon.default, _replaceEmoji.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});