define("discourse/plugins/chat/discourse/widgets/chat-header-icon", ["exports", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_exports, _renderGlimmer, _widget, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("chat-header-icon", {
    tagName: "li.header-dropdown-toggle.chat-header-icon",
    services: ["chat"],
    html() {
      if (!this.chat.userCanChat) {
        return;
      }
      return [new _renderGlimmer.default(this, "div.widget-component-connector", (0, _templateFactory.createTemplateFactory)(
      /*
        <Chat::Header::Icon />
      */
      {
        "id": "/OOwVHdi",
        "block": "[[[8,[39,0],null,null,null]],[],false,[\"chat/header/icon\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/widgets/chat-header-icon.js",
        "isStrictMode": false
      }))];
    }
  });
});