define("discourse/plugins/chat/discourse/components/chat/list/empty-state", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class EmptyState extends _component.default {}
  _exports.default = EmptyState;
  _class = EmptyState;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="c-list-empty-state" ...attributes>
        {{yield}}
      </div>
    
  */
  {
    "id": "8E1bsP2D",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"c-list-empty-state\"],[17,1],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/empty-state.js",
    "isStrictMode": true
  }), _class);
});