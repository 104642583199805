define("discourse/plugins/chat/discourse/components/chat-message-separator-date", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "discourse/helpers/concat-class", "discourse-common/helpers/i18n", "discourse/plugins/chat/discourse/modifiers/chat/track-message-separator-date", "@ember/template-factory", "@ember/component"], function (_exports, _component, _modifier, _object, _concatClass, _i18n, _trackMessageSeparatorDate, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ChatMessageSeparatorDate = _exports.default = (_class = (_class2 = class ChatMessageSeparatorDate extends _component.default {
    onDateClick() {
      return this.args.fetchMessagesByDate?.(this.args.message.firstMessageOfTheDayAt);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @message.formattedFirstMessageDate}}
        <div
          class={{concatClass
            "chat-message-separator-date"
            (if @message.newest "with-last-visit")
          }}
          role="button"
          {{on "click" this.onDateClick passive=true}}
        >
          <div
            class="chat-message-separator__text-container"
            {{trackMessageSeparatorDate}}
          >
            <span class="chat-message-separator__text">
              {{@message.formattedFirstMessageDate}}
  
              {{#if @message.newest}}
                <span class="chat-message-separator__last-visit">
                  <span
                    class="chat-message-separator__last-visit-separator"
                  >-</span>
                  {{i18n "chat.last_visit"}}
                </span>
              {{/if}}
            </span>
          </div>
        </div>
  
        <div class="chat-message-separator__line-container">
          <div class="chat-message-separator__line"></div>
        </div>
      {{/if}}
    
  */
  {
    "id": "B+fiNgm7",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"formattedFirstMessageDate\"]],[[[1,\"      \"],[11,0],[16,0,[28,[32,0],[\"chat-message-separator-date\",[52,[30,1,[\"newest\"]],\"with-last-visit\"]],null]],[24,\"role\",\"button\"],[4,[32,1],[\"click\",[30,0,[\"onDateClick\"]]],[[\"passive\"],[true]]],[12],[1,\"\\n        \"],[11,0],[24,0,\"chat-message-separator__text-container\"],[4,[32,2],null,null],[12],[1,\"\\n          \"],[10,1],[14,0,\"chat-message-separator__text\"],[12],[1,\"\\n            \"],[1,[30,1,[\"formattedFirstMessageDate\"]]],[1,\"\\n\\n\"],[41,[30,1,[\"newest\"]],[[[1,\"              \"],[10,1],[14,0,\"chat-message-separator__last-visit\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"chat-message-separator__last-visit-separator\"],[12],[1,\"-\"],[13],[1,\"\\n                \"],[1,[28,[32,3],[\"chat.last_visit\"],null]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-separator__line-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"chat-message-separator__line\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@message\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-separator-date.js",
    "scope": () => [_concatClass.default, _modifier.on, _trackMessageSeparatorDate.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "onDateClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDateClick"), _class.prototype)), _class);
});