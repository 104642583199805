define("discourse/plugins/chat/discourse/components/chat-thread-list", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-modifier", "discourse/components/conditional-loading-spinner", "discourse/lib/is-element-in-viewport", "discourse-common/utils/decorators", "discourse-i18n", "truth-helpers/helpers/eq", "discourse/plugins/chat/discourse/components/chat/thread-list/item", "discourse/plugins/chat/discourse/modifiers/chat/track-message", "ember-this-fallback/deprecations-helper", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _service, _emberModifier, _conditionalLoadingSpinner, _isElementInViewport, _decorators, _discourseI18n, _eq, _item, _trackMessage, _deprecationsHelper, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatThreadList = _exports.default = (_class = (_class2 = class ChatThreadList extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "messageBus", _descriptor3, this);
      _initializerDefineProperty(this, "chatTrackingStateManager", _descriptor4, this);
      _defineProperty(this, "noThreadsLabel", _discourseI18n.default.t("chat.threads.none"));
      _defineProperty(this, "subscribe", (0, _emberModifier.modifier)((element1, _ref) => {
        let [channel1] = _ref;
        this.messageBus.subscribe(`/chat/${channel1.id}`, this.onMessageBus, channel1.channelMessageBusLastId);
        return () => {
          // TODO (joffrey) In drawer we won't have channel anymore at this point
          if (!channel1) {
            return;
          }
          this.messageBus.unsubscribe(`/chat/${channel1.id}`, this.onMessageBus);
        };
      }));
      _defineProperty(this, "fill", (0, _emberModifier.modifier)(element1 => {
        this.resizeObserver = new ResizeObserver(() => {
          if ((0, _isElementInViewport.default)(element1)) {
            this.loadThreads();
          }
        });
        this.resizeObserver.observe(element1);
        return () => {
          this.resizeObserver.disconnect();
        };
      }));
      _defineProperty(this, "loadMore", (0, _emberModifier.modifier)(element1 => {
        this.intersectionObserver = new IntersectionObserver(this.loadThreads);
        this.intersectionObserver.observe(element1);
        return () => {
          this.intersectionObserver.disconnect();
        };
      }));
    }
    get threadsCollection() {
      return this.chatApi.threads(this.args.channel.id, this.handleLoadedThreads);
    }
    loadThreads() {
      this.threadsCollection.load({
        limit: 10
      });
    }
    get threadsManager() {
      return this.args.channel.threadsManager;
    }
    // NOTE: This replicates sort logic from the server. We need this because
    // the thread unread count + last reply date + time update when new messages
    // are sent to the thread, and we want the list to react in realtime to this.
    get sortedThreads() {
      return this.threadsManager.threads.filter(thread1 => !thread1.originalMessage.deletedAt).sort((threadA1, threadB1) => {
        // If both are unread we just want to sort by last reply date + time descending.
        if (threadA1.tracking.unreadCount && threadB1.tracking.unreadCount) {
          if (threadA1.preview.lastReplyCreatedAt > threadB1.preview.lastReplyCreatedAt) {
            return -1;
          } else {
            return 1;
          }
        }
        // If one is unread and the other is not, we want to sort the unread one first.
        if (threadA1.tracking.unreadCount) {
          return -1;
        }
        if (threadB1.tracking.unreadCount) {
          return 1;
        }
        // If both are read, we want to sort by last reply date + time descending.
        if (threadA1.preview.lastReplyCreatedAt > threadB1.preview.lastReplyCreatedAt) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    get lastThread() {
      return this.sortedThreads[this.sortedThreads.length - 1];
    }
    get shouldRender() {
      return !!this.args.channel;
    }
    onMessageBus(busData1) {
      switch (busData1.type) {
        case "delete":
          this.handleDeleteMessage(busData1);
          break;
        case "restore":
          this.handleRestoreMessage(busData1);
          break;
      }
    }
    handleDeleteMessage(data1) {
      const deletedOriginalMessageThread1 = this.threadsManager.threads.findBy("originalMessage.id", data1.deleted_id);
      if (!deletedOriginalMessageThread1) {
        return;
      }
      deletedOriginalMessageThread1.originalMessage.deletedAt = new Date();
    }
    handleRestoreMessage(data1) {
      const restoredOriginalMessageThread1 = this.threadsManager.threads.findBy("originalMessage.id", data1.chat_message.id);
      if (!restoredOriginalMessageThread1) {
        return;
      }
      restoredOriginalMessageThread1.originalMessage.deletedAt = null;
    }
    handleLoadedThreads(result1) {
      return result1.threads.map(thread1 => {
        const threadModel1 = this.threadsManager.add(this.args.channel, thread1, {
          replace: true
        });
        this.chatTrackingStateManager.setupChannelThreadState(this.args.channel, result1.tracking);
        return threadModel1;
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRender}}
        <div class="chat-thread-list" {{this.subscribe @channel}}>
          <div class="chat-thread-list__items" {{this.fill}}>
  
            {{#each this.sortedThreads key="id" as |thread|}}
              <ChatThreadListItem
                @thread={{thread}}
                {{(if
                  (eq thread this.lastThread)
                  (modifier ChatTrackMessage this.load)
                )}}
              />
            {{else}}
              {{#if this.threadsCollection.fetchedOnce}}
                <div class="chat-thread-list__no-threads">
                  {{this.noThreadsLabel}}
                </div>
              {{/if}}
            {{/each}}
  
            <ConditionalLoadingSpinner
              @condition={{this.threadsCollection.loading}}
            />
  
            <div {{this.loadMore}}>
              <br />
            </div>
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "Y7QvOG2B",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-thread-list\"],[4,[30,0,[\"subscribe\"]],[[30,1]],null],[12],[1,\"\\n        \"],[11,0],[24,0,\"chat-thread-list__items\"],[4,[30,0,[\"fill\"]],null,null],[12],[1,\"\\n\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"sortedThreads\"]]],null]],null],\"id\",[[[1,\"            \"],[8,[32,0],[[4,[52,[28,[32,1],[[30,2],[30,0,[\"lastThread\"]]],null],[50,[30,0,[\"ChatTrackMessage\"]],2,[[30,0,[\"load\"]]],null]],null,null]],[[\"@thread\"],[[30,2]]],null],[1,\"\\n\"]],[2]],[[[41,[30,0,[\"threadsCollection\",\"fetchedOnce\"]],[[[1,\"              \"],[10,0],[14,0,\"chat-thread-list__no-threads\"],[12],[1,\"\\n                \"],[1,[30,0,[\"noThreadsLabel\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n          \"],[8,[32,2],null,[[\"@condition\"],[[30,0,[\"threadsCollection\",\"loading\"]]]],null],[1,\"\\n\\n          \"],[11,0],[4,[30,0,[\"loadMore\"]],null,null],[12],[1,\"\\n            \"],[10,\"br\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[32,3],[\"[[\\\"The `ChatTrackMessage` property path was used in the `/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-thread-list.js` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.ChatTrackMessage}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"@channel\",\"thread\"],false,[\"if\",\"each\",\"-track-array\",\"modifier\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-thread-list.js",
    "scope": () => [_item.default, _eq.default, _conditionalLoadingSpinner.default, _deprecationsHelper.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatTrackingStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "threadsCollection", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "threadsCollection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadThreads", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "loadThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedThreads", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedThreads"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMessageBus", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onMessageBus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleLoadedThreads", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleLoadedThreads"), _class.prototype)), _class);
});