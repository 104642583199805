define("discourse/plugins/chat/discourse/components/chat/message-creator/search-input", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _dIcon, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ChatMessageCreatorSearchInput extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "filterPlaceholder", _discourseI18n.default.t("chat.new_message_modal.filter"));
    }
  }
  _exports.default = ChatMessageCreatorSearchInput;
  _class = ChatMessageCreatorSearchInput;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="chat-message-creator__search-input-container">
        <div class="chat-message-creator__search-input">
          {{icon
            "search"
            class="chat-message-creator__search-input__search-icon"
          }}
          <Input
            class="chat-message-creator__search-input__input"
            placeholder={{this.filterPlaceholder}}
            {{on "input" @onFilter}}
          />
        </div>
      </div>
    
  */
  {
    "id": "lVtzk+9z",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__search-input-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-creator__search-input\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"search\"],[[\"class\"],[\"chat-message-creator__search-input__search-icon\"]]]],[1,\"\\n        \"],[8,[32,1],[[24,0,\"chat-message-creator__search-input__input\"],[16,\"placeholder\",[30,0,[\"filterPlaceholder\"]]],[4,[32,2],[\"input\",[30,1]],null]],null,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@onFilter\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/search-input.js",
    "scope": () => [_dIcon.default, _component2.Input, _modifier.on],
    "isStrictMode": true
  }), _class);
});